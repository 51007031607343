import React, { useEffect, useState } from "react";
import BlockContent from "../components/BlockContent";
import { graphql } from "gatsby";
import Nav from "../components/Nav";
import Skille from "../images/skille_aaker.png";
import Footer from "../components/Footer";
import OutSourceButton from "../components/OutSourceButton";
import Button from "../components/Button";

const ComponentBox = ({ heading, text }) => {
  return (
    <div className="md:w-5/6 bg-white shadow-md p-4 mt-4 text-center place-content-center">
      <div className="grid place-content-center">
        <svg
          width="33"
          height="32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
            fill="#0FF"
          />
        </svg>
      </div>
      <div className="text-2xl py-4">{heading}</div>
      <div className="text-left">
        {" "}
        <BlockContent blocks={text} />
      </div>
    </div>
  );
};

const Fordel = ({
  data: {
    sanityAdvantages: {
      mainHeading,
      introduction,
      heading1,
      text1,
      heading2,
      text2,
      heading3,
      separatorImage,
      separatorHeading,
      separatorText,
      text3,
      heading4,
      headingFullTextBox,
      fullTextBox,
      text4,
      longerText,
      priceExampleHeading,
      priceExample,
      boxHeading1,
      boxText1,
      boxHeading2,
      boxText2,
    },
  },
}) => {
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);

  const handleCheck = (code) => {
    //You found the code :D
    if (value == "RYDDI5") {
      setShow(true);
      console.log("HEIII");
    }
  };

  useEffect(() => {}, [show]);

  //Code to check against i shere, this is for initial testing to see if this meets customer reuirements
  return (
    <>
      <Nav />
      {!show ? (
        <div className="flex flex-col py-16">
          <div className="text-heading-3 text-center"> Skriv inn kode: </div>
          <div className="flex place-content-center space-x-4">
            <input
              onChange={(e) => setValue(e.target.value)}
              className="border-2 mt-4"
            ></input>
            <button
              onClick={handleCheck}
              className="py-2 px-6
              font-bold mt-4 rounded-full transition-colors bg-accent hover:bg-accent-hover text-gray-dark"
            >
              Bekreft
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="px-8">
            <div className="text-heading-4 md:text-heading-2 text-gray-dark mb-2 pt-16 pb-8">
              {mainHeading}
            </div>
            <div className="text-lg pb-16">
              <BlockContent blocks={introduction} />
            </div>
            <div className="md:grid md:grid-cols-2 place-content-center pb-8 md:pl-16 pl-0 ">
              <ComponentBox heading={heading1} text={text1} />
              <ComponentBox heading={heading2} text={text2} />
              <ComponentBox heading={heading3} text={text3} />
              <ComponentBox heading={heading4} text={text4} />
            </div>
          </div>

          <div className="flex place-content-center center py-4 ">
            <ComponentBox heading={headingFullTextBox} text={fullTextBox} />
          </div>
          <div className="bg-gray-dark w-full py-16 text-white lg:grid grid-cols-2 px-4">
            <div className="">
              <img src={Skille} />
            </div>
            <div className="flex flex-col">
              <div className="text-heading-4 md:text-heading-2 text-white px-8 pb-8">
                {separatorHeading}
              </div>
              <div className="text-lg px-8">
                <BlockContent blocks={separatorText} />
              </div>
              <div className="p-8 flex md:flex-row flex-col place-content-evenly">
                <div className="md:pb-0 pb-8">
                  <OutSourceButton />
                </div>
                <div>
                  <a
                    className="py-2 px-6
     font-bold mt-4 rounded-full transition-colors bg-accent hover:bg-accent-hover text-gray-dark"
                    href="https://ryddi.no/gjeldsforhandling"
                  >
                    Økonomisk restart
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4">
            <div className="px-8 pt-8 text-heading-6">
              <BlockContent blocks={longerText} />
            </div>
          </div>
          <div className="flex md:flex-row flex-col py-8 place-content-evenly text-center md:pr-20 pr-0">
            <div className="grid place-content-center"></div>
            <div className="md:w-1/3 bg-white shadow-md p-4 mb-4">
              <div className="grid place-content-center">
                <svg
                  width="33"
                  height="32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                    fill="#0FF"
                  />
                </svg>
              </div>
              <div>{boxText1}</div>
            </div>
            <div className="md:w-1/3 bg-white shadow-md p-4 mb-4">
              <div className="grid place-content-center">
                <svg
                  width="33"
                  height="32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                    fill="#0FF"
                  />
                </svg>
              </div>
              <div>{boxText2}</div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Fordel;

export const query = graphql`
  query {
    sanityAdvantages(_id: { regex: "/advantages$/" }) {
      mainHeading
      introduction: _rawIntroduction
      heading1
      text1: _rawText1
      heading2
      text2: _rawText2
      heading3
      text3: _rawText3
      heading4
      text4: _rawText4
      headingFullTextBox
      fullTextBox: _rawFullTextBox
      separatorImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      separatorHeading
      separatorText: _rawSeparatorText
      longerText: _rawLongerText
      priceExampleHeading
      boxText1
      boxText2
    }
  }
`;
