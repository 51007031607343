import React from "react";

const OutSourceButton = () => {
  return (
    <a
      className="py-2 px-6
     font-bold mt-4 rounded-full transition-colors bg-accent hover:bg-accent-hover text-gray-dark"
      href="https://ryddi.no/ulike-lan"
    >
      Søk her
    </a>
  );
};

export default OutSourceButton;
